import { SEO } from '@root/src/components/legacy/mol.seo/seo.component'
import { ClientDoctorSelectContext } from '@root/src/context/client-doctor-select'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { Query } from '@root/src/data/graphql/graphql-types'
import { PageProps } from '@root/src/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getClientAndDoctorUrl } from 'utils/url'
import { graphql, navigate } from 'gatsby'
import React from 'react'
import { ContactUsPageContent } from '../../components/org.contact-us-page-content/contact-us-page-content.component'

interface ContactPageProps {}

const ContactPage: React.FunctionComponent<PageProps<ContactPageProps, Query>> = props => {
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)
  const seoData = props.data.cosmicjsFaleConosco.metadata.seo
  const siteUrl = props.data.site.siteMetadata.siteUrl

  return (
    <ClientDoctorSelectContext.Provider
      value={{
        clientUrl: clientPath,
        doctorUrl: doctorPath
      }}
    >
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(siteUrl, appPaths.contact.path),
          title: seoData.titulo,
          image: seoData.imagem.url,
          imageAlt: seoData.imagemAlt,
          description: seoData.descricao
        }}
      />
      <IndexLayout location={props.location}>
        <ContactUsPageContent data={props.data.cosmicjsFaleConosco} isDoctor={true} />
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default ContactPage

export const query = graphql`
  query FaleConoscoDetailDoctor {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cosmicjsFaleConosco(slug: { eq: "medico" }) {
      id
      metadata {
        faqTitulo
        faqConteudo
        atendimentoTelefoneTitulo
        atendimentoTelefoneConteudo
        atendimentoOnlineTitulo
        atendimentoOnlineConteudo
        email_padrao
        assuntos {
          tituloAssunto
          emailAssunto
        }
        seo {
          titulo
          descricao
          imagem {
            url
          }
          imagemAlternativa
        }
      }
    }
  }
`
